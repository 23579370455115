import { render } from 'react-dom'
import React, { Suspense } from 'react'
import { Canvas } from 'react-three-fiber'
import { useProgress, Html } from '@react-three/drei'
import { HashRouter as Router } from 'react-router-dom'

import Scene1 from './Scene1'

import "./base.css"

function Loader() {
  const { progress } = useProgress()
  return (
    <Html center>
      <span style={{ color: '#FFFFFF' }}>{progress} % loaded</span>
    </Html>
  )
}

function App(props) {
  const { scene = 1 } = props
  return (
    <Canvas concurrent shadowMap camera={{ position: [0, 0, 5], fov: 70 }}>
      <color attach="background" args={['#000000']} />
      <Suspense fallback={<Loader />}>
        {scene === 1 && <Scene1 />}
      </Suspense>
      <ambientLight intensity={0.4} />
    </Canvas>
  )
}

function Body() {
  return (
    <Router>
      <main>
        <div className="frame">
          <div className="frame__title-wrap">
            <h1 className="frame__title">[ GOSHI LIVE ]</h1>
          </div>
          <div className="frame__links">
            <a href="https://goshi.live/">Home</a>
            <a href="https://goshi.live/the-project/">The Project</a>
            <a href="https://player.goshi.live/">Listen Now</a>
          </div>
          <div className="frame__demos">
            <a href="https://goshi.live/">Insta</a>
            <a href="https://goshi.live/">Pinterest</a>
            <a href="https://goshi.live/">Data & Cookies Policy</a>
          </div>
        </div>
        <div className="content">
          <App scene={1} />
        </div>
      </main>
    </Router>
  )
}

render(<Body />, document.querySelector('#root'))
